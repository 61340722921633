var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    [
      _c("v-autocomplete", {
        attrs: {
          items: _vm.currencyCodes,
          label: "Currency",
          "persistent-hint": "",
          "hide-selected": "",
          "menu-props": { offsetY: true },
        },
        model: {
          value: _vm.selectedCurrency,
          callback: function ($$v) {
            _vm.selectedCurrency = $$v
          },
          expression: "selectedCurrency",
        },
      }),
      _c("v-autocomplete", {
        attrs: {
          items: _vm.list,
          loading: _vm.loading,
          disabled: _vm.gameSelectDisabled,
          label: "Games",
          "item-text": "name",
          "item-value": "game_id",
          "persistent-hint": "",
          "return-object": "",
          "hide-selected": "",
          "menu-props": { offsetY: true },
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function ({ item }) {
              return [
                _c(
                  "v-list-item-content",
                  [
                    _c("v-list-item-title", [_vm._v(_vm._s(item.name))]),
                    _c("v-list-item-subtitle", [_vm._v(_vm._s(item.game_id))]),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.selectedGame,
          callback: function ($$v) {
            _vm.selectedGame = $$v
          },
          expression: "selectedGame",
        },
      }),
      _c("v-autocomplete", {
        attrs: {
          items: _vm.betSet,
          loading: _vm.loading || _vm.betSetLoading,
          disabled: _vm.betSelectDisabled,
          label: "Bet",
          "persistent-hint": "",
          "hide-selected": "",
          "menu-props": { offsetY: true },
        },
        model: {
          value: _vm.selectedBet,
          callback: function ($$v) {
            _vm.selectedBet = $$v
          },
          expression: "selectedBet",
        },
      }),
      _c(
        "v-btn",
        {
          attrs: {
            color: "primary",
            disabled: _vm.gameCurrencyComboAlreadyInUse,
          },
          on: { click: _vm.addGame },
        },
        [_vm._v(" Add game ")]
      ),
      _c("v-divider"),
      _c("v-data-table", {
        attrs: { headers: _vm.headers, items: _vm.gamesList },
        scopedSlots: _vm._u(
          [
            {
              key: `item.bet`,
              fn: function ({ item }) {
                return [_vm._v(" " + _vm._s(item.configuration.bet) + " ")]
              },
            },
            {
              key: `item.currency`,
              fn: function ({ item }) {
                return [_vm._v(" " + _vm._s(item.configuration.currency) + " ")]
              },
            },
            {
              key: `item.actions`,
              fn: function ({ item }) {
                return [
                  _c("v-hover", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ hover }) {
                            return [
                              _c(
                                "v-btn",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeGame(item)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        color: hover ? "red" : undefined,
                                      },
                                    },
                                    [_vm._v("mdi-close")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }