var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "form",
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c("v-text-field", {
        attrs: {
          disabled: _vm.disable && _vm.disable.includes("name"),
          rules: [_vm.rules.required],
          label: "Name",
        },
        model: {
          value: _vm.formData.name,
          callback: function ($$v) {
            _vm.$set(_vm.formData, "name", $$v)
          },
          expression: "formData.name",
        },
      }),
      _c("v-text-field", {
        attrs: {
          disabled: _vm.disable && _vm.disable.includes("code"),
          rules: [_vm.rules.required],
          label: "Code",
        },
        model: {
          value: _vm.formData.offer_code,
          callback: function ($$v) {
            _vm.$set(_vm.formData, "offer_code", $$v)
          },
          expression: "formData.offer_code",
        },
      }),
      _c("DatetimePickerVue", {
        key: _vm.formData.valid_from,
        attrs: {
          title: "From",
          min: _vm.dateNow,
          max: _vm.dateMax,
          disabled: _vm.disable && _vm.disable.includes("valid_from"),
          rules: [_vm.rules.required],
        },
        model: {
          value: _vm.formData.valid_from,
          callback: function ($$v) {
            _vm.$set(_vm.formData, "valid_from", $$v)
          },
          expression: "formData.valid_from",
        },
      }),
      _c("DatetimePickerVue", {
        key: _vm.formData.valid_to,
        attrs: {
          title: "To",
          min: _vm.dateMin,
          disabled: _vm.disable && _vm.disable.includes("valid_to"),
          rules: _vm.timeToRules,
        },
        on: { click: _vm.updateTo },
        model: {
          value: _vm.formData.valid_to,
          callback: function ($$v) {
            _vm.$set(_vm.formData, "valid_to", $$v)
          },
          expression: "formData.valid_to",
        },
      }),
      _c("v-text-field", {
        attrs: {
          disabled: _vm.disable && _vm.disable.includes("spins"),
          rules: [_vm.rules.required],
          label: "Spins",
        },
        model: {
          value: _vm.formData.spins,
          callback: function ($$v) {
            _vm.$set(_vm.formData, "spins", _vm._n($$v))
          },
          expression: "formData.spins",
        },
      }),
      _vm.showGamble
        ? _c("v-checkbox", {
            attrs: {
              disabled: _vm.disable && _vm.disable.includes("gamble"),
              label: "Gamble",
            },
            model: {
              value: _vm.formData.gamble_enabled,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "gamble_enabled", $$v)
              },
              expression: "formData.gamble_enabled",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }