<template>
	<v-form
		ref="form"
		v-model="valid">
		<v-text-field
			:disabled="disable && disable.includes('name')"
			:rules="[rules.required]"
			v-model="formData.name"
			label="Name"></v-text-field>
		<v-text-field
			:disabled="disable && disable.includes('code')"
			:rules="[rules.required]"
			v-model="formData.offer_code"
			label="Code"></v-text-field>
		<DatetimePickerVue
			title="From"
			:min="dateNow"
			:max="dateMax"
			:key="formData.valid_from"
			:disabled="disable && disable.includes('valid_from')"
			:rules="[rules.required]"
			v-model="formData.valid_from"></DatetimePickerVue>
		<DatetimePickerVue
			title="To"
			:min="dateMin"
			:key="formData.valid_to"
			:disabled="disable && disable.includes('valid_to')"
			:rules="timeToRules"
			@click="updateTo"
			v-model="formData.valid_to"></DatetimePickerVue>
		<v-text-field
			:disabled="disable && disable.includes('spins')"
			:rules="[rules.required]"
			v-model.number="formData.spins"
			label="Spins"></v-text-field>
		<v-checkbox
			v-if="showGamble"
			:disabled="disable && disable.includes('gamble')"
			v-model="formData.gamble_enabled"
			label="Gamble" />
	</v-form>
</template>
<script>
import DatetimePickerVue from "../forms/DatetimePicker.vue";
import { FEATURE_FLAGS } from "../../constants/constants";
export default {
	model: {
		prop: "value",
		event: "change",
	},
	props: ["value", "disable"],
	components: { DatetimePickerVue },
	data() {
		return {
			showGamble: FEATURE_FLAGS.FREE_GAMES_GAMBLE_SUPPORT,
			valid: true,
			dateNow: new Date(),
			timeToRules: [],
			formData: {
				name: this.value?.name ?? "10 spins",
				offer_code: this.value?.offer_code ?? "10spins",
				valid_from: this.value?.valid_from ?? new Date().toISOString(),
				valid_to: this.value?.valid_to ?? null,
				spins: this.value?.spins ?? 10,
				gamble_enabled: this.value?.gamble_enabled ?? false,
			},
			rules: {
				required: (v) => {
					return (
						(v !== null && v !== undefined && v.toString().length > 0) ||
						"This field is required"
					);
				},
			},
		};
	},
	watch: {
		formData: {
			async handler(newVal) {
				await this.$nextTick();
				if (this.valid) {
					this.$emit("change", newVal);
				}
			},
			deep: true,
		},
	},
	computed: {
		dateMax() {
			if (!this.formData.valid_to) return;
			return new Date(this.formData.valid_to);
		},
		dateMin() {
			if (!this.formData.valid_from) return;
			return new Date(this.formData.valid_from);
		},
	},
	methods: {
		updateTo() {
			this.timeToRules = [];
		},
		async submitHandler() {
			this.timeToRules = [
				(e) => {
					if (new Date(this.formData.valid_to) < new Date().setSeconds(0, 0)) {
						return "Created offer must not be expired.";
					}
					return true;
				},
			];
			await this.$nextTick();
			if (this.$refs.form.validate()) {
				return true;
			}
			return false;
		},
	},
	created() {
		this.$emit("change", this.formData);
		this.dateNow = new Date();
	},
};
</script>
